/* eslint-disable import/no-mutable-exports */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { cloneElement, lazy } from 'react';
import { Router as ReactRouter } from 'react-router';
import { Route } from 'react-router-dom';

import Breadcrumbs from 'Component/Breadcrumbs';
import CookiePopup from 'Component/CookiePopup';
import DemoNotice from 'Component/DemoNotice';
import Footer from 'Component/Footer';
import GoogleTagManager from 'Component/GoogleTagManager';
import Header from 'Component/Header';
import Klarna from 'Component/Klarna';
import Meta from 'Component/Meta';
import NewVersionPopup from 'Component/NewVersionPopup';
import NotificationList from 'Component/NotificationList';
import PromotionBar from 'Component/PromotionBar';
import UrlRewrites from 'Route/UrlRewrites';
import { ABsplit } from 'Route/UrlRewrites/UrlRewrites.component';
import {
    CartPage,
    Checkout,
    CmsPage,
    HomePage,
    MenuPage,
    Router as SourceRouter,
    SearchPage,
    WishlistShared,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';
import {
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    SWITCH_ITEMS_TYPE
} from 'SourceComponent/Router/Router.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getLocaleFromUrl } from 'Util/Global';
import history from 'Util/History';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export {
    CartPage,
    Checkout,
    CmsPage,
    HomePage,
    SearchPage,
    MenuPage,
    WishlistShared,
    withStoreRegex
};

export const WishlistPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "wishlist" */ 'Route/WishlistPage'
));
export const StoreFinder = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "storefinder" */ 'Route/StoreFinder'
));
export const PostsListing = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "bloglist" */ 'Route/PostsListing'
));
export const PostsDetails = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "blogpost" */ 'Route/PostsDetails'
));
export const Categories = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "categories" */ 'Route/Categories'
));
export const NewsletterPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "newsletter" */ 'Route/NewsletterPage'
));
export const DYOPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "dyo" */ 'Route/DYOPage'));
export const FabricSamplesPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "fabric-samples" */ 'Route/FabricSamplesPage'
));
export const ParamPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "param" */ 'Route/ParamPage'));
export const CartRestore = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "CartRestore" */ 'Route/CartRestore'
));
export const SetupShowroomPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "SetupShowroomPage" */ 'Route/SetupShowroomPage'
));
export const AddProductsToCartPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "AddProductsToCartPage" */ 'Route/AddProductsToCartPage'
));
export const CountryFinder = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "SetupShowroomPage" */ 'Route/CountryFinder'
));
export const Showroom = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "Showroom" */ 'Route/Showroom'
));
export const ShowroomSuccess = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "ShowroomSuccess" */ 'Route/ShowroomSuccess'
));
export const ProfessionalSelector = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "ProfessionalSelector" */ 'Route/ProfessionalSelector'
));
export const ProfessionalRedirect = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "ProfessionalRedirect" */ 'Route/ProfessionalRedirect'
));
export const PasswordUpdate = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/PasswordUpdate'
));
export const CreateAccountPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/CreateProfessionalAccount'
));
export const LoginAccountPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/LoginProfessionalAccount'
));
export const ForgotPasswordPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/ForgotProfessionalPassword'
));
export const MyAccount = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'
));
// eslint-disable-next-line fp/no-let
export let routes = {};

export const routesArray = [];
routesArray['da-dk'] = {
    categories: 'kategorier',
    designyourownsofa: 'design-din-egen-sofa',
    fabricsamples: 'stofprover'
};
routesArray['sv-se'] = {
    categories: 'kategorier',
    designyourownsofa: 'designa-sjalv',
    fabricsamples: 'tygprover'
};
routesArray['nb-no'] = {
    categories: 'kategorier',
    designyourownsofa: 'bygg-din-egen-sofa',
    fabricsamples: 'tekstilprover'
};
routesArray['nl-nl'] = {
    categories: 'categorieen',
    designyourownsofa: 'zelf-ontwerpen',
    fabricsamples: 'stofstalen'
};
routesArray['nl-be'] = {
    categories: 'categorieen',
    designyourownsofa: 'zelf-ontwerpen',
    fabricsamples: 'stofstalen'
};
routesArray['de-ch'] = {
    categories: 'kategorien',
    designyourownsofa: 'sofa-konfigurator',
    fabricsamples: 'stoffproben'
};
routesArray['de-de'] = {
    categories: 'kategorien',
    designyourownsofa: 'sofa-konfigurator',
    fabricsamples: 'stoffproben'
};
routesArray['de-at'] = {
    categories: 'kategorien',
    designyourownsofa: 'sofa-konfigurator',
    fabricsamples: 'stoffproben'
};
routesArray['fr-be'] = {
    categories: 'categories',
    designyourownsofa: 'mon-design',
    fabricsamples: 'echantillons-de-tissu'
};
routesArray['fr-ch'] = {
    categories: 'categories',
    designyourownsofa: 'mon-design',
    fabricsamples: 'echantillons-de-tissu'
};
routesArray['fr-fr'] = {
    categories: 'categories',
    designyourownsofa: 'mon-design',
    fabricsamples: 'echantillons-de-tissu'
};
routesArray['en-int'] = {
    categories: 'categories',
    designyourownsofa: 'design-your-own-sofa',
    fabricsamples: 'fabricsamples'
};

export const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
});

if (params.variant !== null) {
    BrowserDatabase.setItem(params.variant, 'ab_variant', ONE_MONTH_IN_SECONDS);
}

// const { ConfigReducer: { locale: storeLocal } } = getStore().getState();
// export const modLocal = convertTolocal(storeLocal);
export const locale = getLocaleFromUrl();

switch (locale) {
case 'da-dk':
    routes = routesArray['da-dk'];
    break;
case 'sv-se':
    routes = routesArray['sv-se'];
    break;
case 'nb-no':
    routes = routesArray['nb-no'];
    break;
case 'nl-nl':
    routes = routesArray['nl-nl'];
    break;
case 'nl-be':
    routes = routesArray['nl-be'];
    break;
case 'de-ch':
    routes = routesArray['de-ch'];
    break;
case 'de-de':
    routes = routesArray['de-de'];
    break;
case 'de-at':
    routes = routesArray['de-at'];
    break;
case 'fr-be':
    routes = routesArray['fr-be'];
    break;
case 'fr-ch':
    routes = routesArray['fr-ch'];
    break;
case 'fr-fr':
    routes = routesArray['fr-fr'];
    break;
case 'en-int':
    routes = routesArray['en-int'];
    break;
default:
    routes = routesArray['en-us'];
    break;
}

export const countrySelectPage = ['be', 'ch'];

/** @namespace Sofacompany/Component/Router/Component/Router */
export class Router extends SourceRouter {
  [BEFORE_ITEMS_TYPE] = [
      {
          component: <GoogleTagManager />,
          position: 5
      },
      {
          component: <NotificationList />,
          position: 10
      },
      {
          component: <DemoNotice />,
          position: 15
      },
      {
          component: <PromotionBar />,
          position: 20
      },
      {
          component: <Header />,
          position: 25
      },
      {
          component: <Breadcrumbs />,
          position: 30
      },
      {
          component: <NewVersionPopup />,
          position: 35
      },
      {
          component: <CookiePopup />,
          position: 40
      },
      {
          component: <Klarna />,
          position: 45
      }
  ];

  [SWITCH_ITEMS_TYPE] = [
      {
          component: (
        <Route
          path={ withStoreRegex('/') }
          exact
          render={ (props) => <HomePage { ...props } /> }
        />
          ),
          position: 10
      },
      {
          component: (
        <Route
          path={ withStoreRegex(`/${routes.categories}`) }
          render={ (props) => <Categories { ...props } /> }
        />
          ),
          position: 15
      },
      {
          component: (
        <Route
          path={ withStoreRegex(`/${routes.designyourownsofa}`) }
          render={ (props) => <DYOPage { ...props } /> }
        />
          ),
          position: 16
      },
      {
          component: (
        <Route
          path={ withStoreRegex(`/${routes.fabricsamples}`) }
          render={ (props) => <FabricSamplesPage { ...props } /> }
        />
          ),
          position: 17
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/search/:query/') }
          render={ (props) => <SearchPage { ...props } /> }
        />
          ),
          position: 25
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/page') }
          render={ (props) => <CmsPage { ...props } /> }
        />
          ),
          position: 40
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/cart') }
          exact
          render={ (props) => <CartPage { ...props } /> }
        />
          ),
          position: 50
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/checkout/:step?') }
          render={ (props) => <Checkout { ...props } /> }
        />
          ),
          position: 60
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/menu') }
          render={ (props) => <MenuPage { ...props } /> }
        />
          ),
          position: 70
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/wishlist') }
          render={ (props) => <WishlistPage { ...props } /> }
        />
          ),
          position: 80
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/wishlist/shared/:code') }
          render={ (props) => <WishlistShared { ...props } /> }
        />
          ),
          position: 81
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/newsletter') }
          component={ (props) => <NewsletterPage { ...props } /> }
        />
          ),
          position: 85
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/blog/:handle') }
          component={ (props) => <PostsDetails { ...props } /> }
        />
          ),
          position: 90
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/blog') }
          component={ (props) => <PostsListing { ...props } /> }
        />
          ),
          position: 100
      },
      {
          component: (
        <Route
          exact
          path={ withStoreRegex('/storelocator') }
          component={ (props) => <StoreFinder { ...props } /> }
        />
          ),
          position: 110
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/storelocator/:id') }
          component={ (props) => <StoreFinder { ...props } /> }
        />
          ),
          position: 120
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/showrooms') }
          component={ (props) => <StoreFinder { ...props } /> }
        />
          ),
          position: 125
      },
      {
          component: (
        <Route
          exact
          path={ withStoreRegex('/cart/restore') }
          component={ (props) => <CartRestore { ...props } /> }
        />
          ),
          position: 12
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/cart/restore/:id') }
          component={ (props) => <CartRestore { ...props } /> }
        />
          ),
          position: 129
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/invoice') }
          component={ (props) => <ParamPage { ...props } /> }
        />
          ),
          position: 130
      },
      {
          component: (
        <Route
          exact
          path={ withStoreRegex('/setup-showroom') }
          component={ (props) => <SetupShowroomPage { ...props } /> }
        />
          ),
          position: 140
      },
      {
          component: (
        <Route
          exact
          path={ countrySelectPage.map((country) => `/${country}`) }
          component={ (props) => <CountryFinder { ...props } /> }
        />
          ),
          position: 150
      },
      {
          component: (
        <Route
          exact
          path={ withStoreRegex('/add-products-to-cart') }
          component={ (props) => <AddProductsToCartPage { ...props } /> }
        />
          ),
          position: 160
      },
      {
          component: (
        <Route
          exact
          path={ withStoreRegex('/showroom/:step?') }
          component={ (props) => <Showroom { ...props } /> }
        />
          ),
          position: 170
      },
      {
          component: (
        <Route
          exact
          path={ withStoreRegex('/professional') }
          component={ (props) => <ABsplit flag="b2b" splitA={ ProfessionalSelector } splitB={ ProfessionalRedirect } props={ props } /> }
        />
          ),
          position: 180
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/my-account/:tab?') }
          render={ (props) => <MyAccount { ...props } /> }
        />
          ),
          position: 70
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/:account*/login/') }
          render={ (props) => <LoginAccountPage { ...props } /> }
        />
          ),
          position: 190
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/:account*/forgotpassword/') }
          render={ (props) => <ForgotPasswordPage { ...props } /> }
        />
          ),
          position: 200
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/:account*/createPassword') }
          render={ (props) => <PasswordUpdate { ...props } /> }
        />
          ),
          position: 60
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/:account*/activate-user/') }
          render={ (props) => <PasswordUpdate { ...props } isActivateUser /> }
        />
          ),
          position: 60
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/:account*/create/') }
          render={ (props) => <CreateAccountPage { ...props } /> }
        />
          ),
          position: 210
      },
      {
          component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
          position: 1000
      }
  ];

  [AFTER_ITEMS_TYPE] = [
      {
          component: <Footer />,
          position: 10
      }
  ];

  renderItemsOfType(type) {
      // eslint-disable-next-line fp/no-let
      let incrementKey = 0;
      return this.getSortedItems(type).map(({ position, component }) => {
          incrementKey++;
          const inc = `inc${incrementKey}`;
          return cloneElement(component, { key: position || inc });
      });
  }

  render() {
      const { currentPageName } = this.props;

      return (
      <div block={ currentPageName }>
        <Meta />
        <ReactRouter history={ history }>
          { this.renderRouterContent() }
        </ReactRouter>
      </div>
      );
  }
}

export default Router;
