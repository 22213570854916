export * from 'SourceComponent/StoreSwitcher/StoreSwitcher.config';

/* eslint-disable dot-notation */
export const STORESWITCHER_OVERLAY = 'storeswitcher_overlay';

export const HIDDEN_STORES = ['vn', 'za', 'fr', 'en', 'fr_ch', 'int'];

export const storeCodes = [];
storeCodes['dk'] = {
    group: 'dk',
    full: 'da_DK'
};
storeCodes['se'] = {
    group: 'dk',
    full: 'sv_SE'
};
storeCodes['no'] = {
    group: 'dk',
    full: 'nb_NO'
};
storeCodes['nl'] = {
    group: 'nl',
    full: 'nl_NL'
};
storeCodes['be'] = {
    group: 'be',
    full: 'nl_BE'
};
storeCodes['fr_be'] = {
    group: 'be',
    full: 'fr_BE'
};
storeCodes['ch'] = {
    group: 'ch',
    full: 'de_CH'
};
storeCodes['fr_ch'] = {
    group: 'ch',
    full: 'fr_CH'
};
storeCodes['de'] = {
    group: 'de',
    full: 'de_DE'
};
storeCodes['at'] = {
    group: 'de',
    full: 'de_AT'
};
storeCodes['fr'] = {
    group: 'fr',
    full: 'fr_FR'
};
storeCodes['int'] = {
    group: 'int',
    full: 'en_INT'
};
