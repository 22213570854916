import {
    ConfigQuery as SourceConfigQuery
} from 'SourceQuery/Config.query';
import { Field } from 'Util/Query';
/** @namespace Sofacompany/Query/Config/Query/ConfigQuery */
export class ConfigQuery extends SourceConfigQuery {
    _getStoreListFields() {
        return [
            'name',
            'is_active',
            'base_url',
            'base_link_url',
            'code',
            'locale',
            'default_country'
        ];
    }

    getCustomerServiceNumber() {
        const query = new Field('sc_storelocator')
            .addFieldList([
                'store_name: name',
                'store_phone: storelocator_phone',
                'store_id : storelocator_country_id'
            ])
            .setAlias('storeNumber');

        return query;
    }

    _getStoreConfigFields() {
        return [
            'id',
            'code',
            'is_active',
            'cms_home_page',
            'cms_no_route',
            'copyright',
            'timezone',
            'header_logo_src',
            'timezone',
            'title_prefix',
            'title_suffix',
            'default_display_currency_code',
            'default_keywords',
            'default_title',
            'default_description',
            'default_country',
            'downloadable_links_target_new_window',
            'secure_base_media_url',
            'logo_alt',
            'logo_height',
            'logo_width',
            'locale',
            'cookie_text',
            'cookie_link',
            'terms_are_enabled',
            'address_lines_quantity',
            'base_url',
            'pagination_frame',
            'pagination_frame_skip',
            'anchor_text_for_previous',
            'anchor_text_for_next',
            'reviews_are_enabled',
            'reviews_allow_guest',
            'wishlist_general_active',
            'demo_notice',
            'guest_checkout',
            'is_email_confirmation_required',
            'display_product_stock_status',
            'base_link_url',
            'show_vat_number_on_storefront',
            'show_tax_vat_number',
            'product_use_categories',
            'category_url_suffix',
            'cookie_lifetime',
            'plp_list_mode',
            'layered_navigation_product_count_enabled',
            'region_display_all',
            'redirect_dashboard',
            'newsletter_general_active',
            'newsletter_subscription_allow_guest_subscribe',
            'newsletter_subscription_confirm',
            this.getPriceDisplayTypeField(),
            'blog_posts_per_page',
            'trustpilot_buid',
            'head_includes',
            'absolute_footer',
            'klevu_search_js_api_key',
            'klevu_search_cloud_search_url',
            'klevu_search_analytics_url',
            'delivery_price_url',
            'klarna_enabled',
            'klaviyo_enabled',
            'klaviyo_api',
            'klaviyo_newsletter_checkout_text',
            'cylindo_id'
        ];
    }
}

export default new ConfigQuery();
