import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Klarna from './Klarna.component';

/** @namespace Sofacompany/Component/ProductInformation/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    klarnaConfig: state.ConfigReducer.klarnaConfig
});

export const mapDispatchToProps = () => ({});

export class KlarnaContainer extends PureComponent {
    static propTypes = {
        klarnaConfig: PropTypes.object.isRequired
    };

    componentDidUpdate() {
        this.loadKlarnaScript();
    }

    loadKlarnaScript() {
        const {
            klarnaConfig: {
                enabled, clientId, environment
            } = {}
        } = this.props;

        if (!enabled || !clientId || !environment) {
            return;
        }

        const timer = 1000;
        const script = document.createElement('script');
        script.async = true;
        script.setAttribute('data-environment', environment);
        script.setAttribute('data-client-id', clientId);
        script.src = 'https://js.klarna.com/web-sdk/v1/klarna.js';

        setTimeout(() => {
            document.head.insertBefore(script, document.head.childNodes[1]);
        }, timer);
    }

    render() {
        return (
            <Klarna />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KlarnaContainer);
