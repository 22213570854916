import { Field } from 'Util/Query';

/** @namespace Sofacompany/Query/Klarna/Query/KlarnaQuery */
export class KlarnaQuery {
    getKlarnaConfig() {
        return new Field('getKlarnaConfig')
            .addField('enabled')
            .addField('environment')
            .addField('clientId')
            .addField('locale');
    }
}

export default new KlarnaQuery();
