import {
    Form as SourceForm
} from 'SourceComponent/Form/Form.component';

/** @namespace Sofacompany/Component/Form/Component/Form */
export class Form extends SourceForm {
    handleFormSubmit = async (e) => {
        const {
            onSubmitSuccess,
            onSubmitError,
            onSubmit,
            id
        } = this.props;

        e.preventDefault();
        onSubmit();

        const portalData = id ? await window.formPortalCollector.collect(id) : [];

        const {
            invalidFields,
            inputValues
        } = portalData.reduce((acc, portalData) => {
            const {
                invalidFields = [],
                inputValues = {}
            } = portalData;

            const {
                invalidFields: initialInvalidFields,
                inputValues: initialInputValues
            } = acc;

            return ({
                invalidFields: [...initialInvalidFields, ...invalidFields],
                inputValues: { ...initialInputValues, ...inputValues }
            });
        }, this.collectFieldsInformation());

        const asyncData = Promise.all(portalData.reduce((acc, { asyncData }) => {
            if (!asyncData) {
                return acc;
            }

            return [...acc, asyncData];
        }, []));

        asyncData.then(
            /** @namespace Sofacompany/Component/Form/Component/then */
            (asyncDataList) => {
                if (!invalidFields.length) {
                    onSubmitSuccess(inputValues, asyncDataList);
                    return;
                }

                onSubmitError(inputValues, invalidFields);
            },
            /** @namespace Sofacompany/Component/Form/Component/then */
            (e) => onSubmitError(inputValues, invalidFields, e)
        );
    };

    checkValidation = () => {
        const {
            id
        } = this.props;

        const portalData = id ? window.formPortalCollector.collect(id) : [];

        const {
            invalidFields
        } = portalData.reduce((acc, portalData) => {
            const {
                invalidFields = []
            } = portalData;

            const {
                invalidFields: initialInvalidFields
            } = acc;

            return ({
                invalidFields: [...initialInvalidFields, ...invalidFields]
            });
        }, this.collectFieldsInformation());

        if (invalidFields) {
            return invalidFields;
        }

        return null;
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    validateField(fieldId) {
        if (window.fieldValidationRegistry && window.fieldValidationRegistry.has(fieldId)) {
            const fieldComponent = window.fieldValidationRegistry.get(fieldId);
            return fieldComponent.runValidateField();
        }

        return false;
    }

    handleValidation = (id, customCheck = false) => {
        const { validationHandler } = this.props;
        const invalidFields = this.checkValidation();
        if (customCheck === null) {
            invalidFields.push('custom_error');
        }
        validationHandler(id, invalidFields);

        invalidFields.forEach((fieldId) => {
            this.validateField(fieldId);
        });
    };

    render() {
        const { mix, id } = this.props;
        const { children, fieldsAreValid } = this.state;

        return (
            <form
              block="Form"
              mix={ mix }
              mods={ { isInvalid: !fieldsAreValid } }
              ref={ (ref) => {
                  this.form = ref;
              } }
              id={ id }
              onSubmit={ this.handleFormSubmit }
            >
                { children }
            </form>
        );
    }
}

export default Form;
