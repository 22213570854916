/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Menu, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import ExtraMenu from 'Component/ExtraMenu';
import Icon from 'Component/Icon';
import Link from 'Component/Link';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import SearchField from 'Component/SearchField';
import StoreSwitcher from 'Component/StoreSwitcher';
import {
    CartOverlay,
    Header as SourceHeader,
    MyAccountOverlay
} from 'SourceComponent/Header/Header.component';
import { isSignedIn } from 'Util/Auth';
import isMobile from 'Util/Mobile';
import { appendWithStoreCode } from 'Util/Url';

import {
    CHECKOUT,
    DISABLED_STORES
} from './Header.config';

export {
    CartOverlay,
    MyAccountOverlay
};

/** @namespace Sofacompany/Component/Header/Component/Header */
export class Header extends SourceHeader {
    static propTypes = {
        ...SourceHeader.propTypes,
        onMenuButtonClick: PropTypes.func.isRequired,
        onStoresButtonClick: PropTypes.func.isRequired,
        wishlistItems: PropTypes.object.isRequired,
        showShadow: PropTypes.bool

    };

    static defaultProps = {
        ...SourceHeader.defaultProps,
        logo_alt: 'Sofacompany logo'
    };

    stateMap = {
        [DEFAULT_STATE_NAME]: {
            back: false,
            logo: true
        },
        [CHECKOUT]: {
            back: true,
            wishlist: false,
            stores: false,
            minicart: false
        }
    };

    renderMap = {
        back: this.renderBackButton.bind(this),
        burgermenu: this.renderMenuButton.bind(this),
        logo: this.renderLogo.bind(this),
        search: this.renderSearchField.bind(this),
        account: this.renderAccount.bind(this),
        stores: this.renderStoresButton.bind(this),
        minicart: this.renderMinicartButton.bind(this)
    };

    renderLogo(isVisible = false) {
        const { navigationState: { name } } = this.props;

        const hidden = name === 'menu' || name === 'menu_subcategory' || (isMobile.any() && name.includes('popup_search'));

        const currentLocation = window.location.pathname;

        const destination = (currentLocation.includes('/checkout')) ? '/cart' : '/';

        return (
            <Link
              to={ appendWithStoreCode(destination) }
              aria-label={ __('Go to homepage by clicking on SOFACOMPANY`s logo') }
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
              block="Header"
              elem="LogoWrapper"
              mods={ { isVisible, hidden } }
              key="logo"
            >
                { this.renderLogoImage() }
            </Link>
        );
    }

    renderAccountButton(isVisible) {
        const {
            onMyAccountButtonClick,
            onAccountHover, handleLogout
        } = this.props;

        return (
            <Menu as="div">
                <Menu.Button
                  block="Header"
                  elem="MyAccountWrapper"
                  tabIndex="0"
                  onClick={ onAccountHover }
                  aria-label="Open my account"
                  id="myAccount"
                >

                    <div
                      block="Header"
                      elem="MyAccountTitle"
                    >
                    <Icon icon="user" />
                    </div>
                    <div
                      block="Header"
                      elem="Button"
                      mods={ { isVisible, type: 'account' } }
                    />
                </Menu.Button>
                <Transition
                  as={ Fragment }
                  enter="MyAccountDropdownTransition-Enter"
                  enterFrom="MyAccountDropdownTransition-EnterFrom"
                  enterTo="MyAccountDropdownTransition-EnterTo"
                  leave="MyAccountDropdownTransition-Leave"
                  leaveFrom="MyAccountDropdownTransition-LeaveFrom"
                  leaveTo="MyAccountDropdownTransition-LeaveTo"
                >
                    <Menu.Items className="MyAccountDropdown">
                            <Menu.Item>
                                { ({ active }) => (
                                    <button onClick={ onMyAccountButtonClick } className={ active ? 'MyAccountDropdown-MenuItem active' : 'MyAccountDropdown-MenuItem' }>
                                        { __('My Account') }
                                    </button>
                                ) }
                            </Menu.Item>
                            <Menu.Item>
                                { ({ active }) => (
                                    <button onClick={ handleLogout } className={ active ? 'MyAccountDropdown-MenuItem active' : 'MyAccountDropdown-MenuItem' }>
                                        { __('Logout') }
                                    </button>
                                ) }
                            </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        );
    }

    renderAccount(isVisible = false) {
        const {
            onAccountLeave
        } = this.props;

        if (!isSignedIn()) {
            return null;
        }

        return (

                <div
                  aria-label="My account"
                  block="Header"
                  elem="MyAccount"
                  onMouseLeave={ onAccountLeave }
                >
                    { this.renderAccountButton(isVisible) }
                </div>

        );
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            curSearch,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay,
            clearSearchInput,
            searchOpen
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <SearchField
              key="search"
              searchOpen={ searchOpen }
              searchCriteria={ searchCriteria }
              curSearch={ curSearch }
              onSearchOutsideClick={ onSearchOutsideClick }
              clearSearchInput={ clearSearchInput }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isVisible={ isVisible }
              isActive={ name.includes('popup_search') }
              hideActiveOverlay={ hideActiveOverlay }
              isButton={ isMobile.any() }
              times={ 0 }
            />
        );
    }

    renderBackButton(isVisible = false) {
        const { onBackButtonClick } = this.props;

        return (
            <button
              key="back"
              block="Header"
              elem="Button"
              mods={ { type: 'back', isVisible } }
              onClick={ onBackButtonClick }
              aria-label={ __('Go back') }
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                <Icon icon="arrow-left" />
            </button>
        );
    }

    renderMenuButton(isActive = false) {
        const { onMenuButtonClick, navigationState: { name } } = this.props;

        if (!isMobile.any()) {
            return null;
        }

        const hidden = name === 'menu' || name === 'menu_subcategory' || name.includes('popup_search');

        return (
            <button
              key="menu"
              block="Header"
              elem="Button"
              mods={ { isActive, type: 'burgermenu' } }
              aria-label={ __('Go to menu and search') }
              onClick={ onMenuButtonClick }
            >
                { !hidden && <Icon icon="menu" /> }
                { hidden && <Icon icon="close" /> }
            </button>
        );
    }

    renderExtraMenu() {
        if (isMobile.any()) {
            return null;
        }

        return (
            <ExtraMenu key="func-menu" isNoScroll />
        );
    }

    renderStoresButton(isVisible = false) {
        const { onStoresButtonClick, storeCode } = this.props;

        if (DISABLED_STORES.includes(storeCode)) {
            return null;
        }

        return (
            <button
              key="stores"
              block="Header"
              elem="Button"
              mods={ { isVisible } }
              onClick={ onStoresButtonClick }
              aria-label={ __('Stores') }
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                <Icon icon="stores" />
            </button>
        );
    }

    renderWishlistItemsQty() {
        const { wishlistItems } = this.props;

        const wishlistItemsQty = Object.values(wishlistItems).length;

        if (!wishlistItemsQty) {
            return null;
        }

        return (
            <span block="Header" elem="ItemsCount">
                <Icon icon="circle" number={ wishlistItemsQty } />
            </span>
        );
    }

    renderWishlistButton(isVisible = false) {
        const { onWishlistButtonClick } = this.props;

        return (
            <button
              key="wishlist"
              block="Header"
              elem="Button"
              mods={ { isVisible } }
              mix={ { block: 'Header', elem: 'WishlistButton' } }
              onClick={ onWishlistButtonClick }
              aria-label={ __('Wishlist') }
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                <Icon icon="heart" />
                { this.renderWishlistItemsQty() }
            </button>
        );
    }

    renderMinicartItemsQty() {
        const { cartTotals: { items_qty } } = this.props;

        if (!items_qty) {
            return null;
        }

        return (
            <span
              aria-label={ __('Items in cart') }
              role="button"
              block="Header"
              elem="ItemsCount"
            >
                <Icon icon="circle" number={ items_qty } />
            </span>
        );
    }

    renderMinicartButton() {
        const {
            onMinicartButtonClick
        } = this.props;

        return (
            <button
              block="Header"
              elem="MinicartButtonWrapper"
              key="minicart"
              aria-label="minicart"
              tabIndex="0"
              onClick={ onMinicartButtonClick }
            >
                <Icon icon="cart" />
                { this.renderMinicartItemsQty() }
            </button>
        );
    }

    renderSwitcher(isVisible = false) {
        if (isMobile.any()) {
            return null;
        }

        return (
            <div block="Header" elem="Switcher" mods={ { isVisible } } key="switcher">
                <StoreSwitcher />
            </div>
        );
    }

    render() {
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            showShadow,
            hasFetchedPromotionBarOnce,
            promotionHidden,
            restoreCart,
            navigationState
        } = this.props;

        const className = showShadow && navigationState.name === 'pdp' ? 'showShadow HideMenu' : 'showShadow';
        return (
            <header
              block="Header"
              mods={ {
                  name,
                  isHiddenOnMobile,
                  isCheckout,
                  restoreCart: !!restoreCart,
                  isPromotion: (hasFetchedPromotionBarOnce && !promotionHidden)
              } }
            >
                    <div
                      block="Header"
                      elem="Wrapper"
                      className={ showShadow ? className : '' }
                      mods={ { isPromotion: (hasFetchedPromotionBarOnce && !promotionHidden) } }
                    >
                        <div block="Header" elem="NavContainer">
                            <nav block="Header" elem="Nav" mods={ { signedin: !!isSignedIn() } }>
                                { this.renderSwitcher() }
                                { this.renderNavigationState() }
                            </nav>
                        </div>
                        <div block="Header" elem="NavContainer">
                            <div block="Header" elem="Nav-Border" />
                        </div>
                        { this.renderMenu() }
                    </div>
            </header>
        );
    }
}

export default Header;
