import { FILE_TYPE } from '@scandipwa/scandipwa/src/component/Field/Field.config';
import PropTypes from 'prop-types';

import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';
import { debounce } from 'Util/Request';

import Field from './Field.component';
import {
    CHECKBOX_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TELEPHONE_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE,
    VAT_TYPE
} from './Field.config';

export const DEBOUNCED_FIELDS = ['street', 'telephone', 'vat_id', 'guest_email', 'postcode'];

/** @namespace Sofacompany/Component/Field/Container/FieldContainer */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        type: PropTypes.oneOf([
            TEXT_TYPE,
            NUMBER_TYPE,
            TEXTAREA_TYPE,
            PASSWORD_TYPE,
            RADIO_TYPE,
            CHECKBOX_TYPE,
            SELECT_TYPE,
            TELEPHONE_TYPE,
            VAT_TYPE
        ]).isRequired
    };

    __construct(props) {
        super.__construct(props);

        const { checked } = props;
        const value = this.getInitialPropsValue();

        this.state = {
            value,
            checked,
            validationMessage: '',
            validationStatus: null,
            eventId: ''
        };

        this.debouncedValidation = debounce(() => {
            this.updateValidationStatus();
            // eslint-disable-next-line no-magic-numbers
        }, 2000);
    }

    componentDidMount() {
        const { type, id } = this.props;
        if (window.fieldValidationRegistry === undefined) {
            window.fieldValidationRegistry = new Map();
        }
        window.fieldValidationRegistry.set(id, this);
        const value = this.getInitialPropsValue();
        const isNonCheckboxWithValue = value && type !== 'checkbox';
        const isCountryField = id === 'country_id';

        if (isNonCheckboxWithValue || isCountryField) {
            this.updateValidationStatus();
        }
    }

    componentDidUpdate(prevProps) {
        const { value: prevValue, checked: prevChecked, isSubmitted: prevSubmitted } = prevProps;
        const {
            value: currentValue,
            checked: currChecked,
            type,
            id,
            isSubmitted
        } = this.props;
        const { eventId } = this.state;

        if (prevValue !== currentValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: currentValue });
        }
        if ((type === CHECKBOX_TYPE || type === RADIO_TYPE) && currChecked !== prevChecked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ checked: currChecked });
        }

        const shouldValidateCity = id === 'city' && currentValue !== '';
        const shouldUpdate = eventId === id || prevSubmitted !== isSubmitted;
        const isNonDebouncedField = !DEBOUNCED_FIELDS.includes(eventId);

        if (shouldValidateCity) {
            this.updateValidationStatus();
        }

        if (shouldUpdate && isNonDebouncedField) {
            this.updateValidationStatus();
            this.setValidationMessage(prevProps);
        }
    }

    runValidateField() {
        return this.updateValidationStatus();
    }

    onChange(event) {
        const { type } = this.props;
        this.setState({ eventId: event?.target?.name });

        if (typeof event === 'string' || typeof event === 'number') {
            return this.handleChange(event);
        }

        if (event.currentTarget.value.length <= 0) {
            this.setState({
                validationStatus: null
            });
        }

        if (DEBOUNCED_FIELDS.includes(event?.target?.name)) {
            this.setState({
                validationStatus: null,
                validationMessage: null
            });
            this.debouncedValidation();
        } else {
            this.updateValidationStatus();
        }

        if (type === FILE_TYPE) {
            return this.handleChange(event.target.value, false, event.target.files[0]);
        }

        return this.handleChange(event.target.value);
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const { customValidationStatus, ...otherProps } = this.props;

        return (
            <Field
              { ...otherProps }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default FieldContainer;
