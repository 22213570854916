export * from 'SourceComponent/ProductActions/ProductActions.config';

export const PRODUCT_SANTANDER_POPUP = 'product-santander-popup';
export const PRODUCT_KLARNA_POPUP = 'product-klarna-popup';
export const PRODUCT_DESCRIPTION_MAXLENGTH = '100';
export const PRODUCT_IN_STORE_POPUP_ID = 'ProductInStorePopup';
export const LIST_IN_STORE_POPUP_ID = 'ListInStorePopup';
export const PRODUCT_STOCK_NUMBER = '5';
export const KLARNAINSTALL = ['DE', 'SE', 'NO', 'AT'];
export const SANTANDER_INSTALL = ['DK'];
export const PRODUCT_SIZE_POPUPBOX = 'PRODUCT_SIZE_POPUPBOX';
export const PRODUCT_TERMS_POPUPBOX = 'PRODUCT_TERMS_POPUPBOX';
export const HANDBUILD_QUALITY = 'HANDBUILD_QUALITY';
export const TRY_FOR_30_DAYS = 'TRY_FOR_30_DAYS';
export const YEARS_GUARANTEE = '5_YEARS_GUARANTEE';
export const HASSLE_FREE_DELIVERY = 'HASSLE_FREE_DELIVERY';
export const HASSLE_FREE_DELIVERY_AND_RETURN = 'HASSLE_FREE_DELIVERY_AND_RETURN';
export const PRODUCT_DESCRIPTION_MAXLENGTH_TRUNCATED = '250';
export const D_PRODUCT_FABRIC_POPUPBOX = 'D_PRODUCT_FABRIC_POPUPBOX';
export const D_PRODUCT_LEGS_POPUPBOX = 'PRODUCT_LEGS_POPUPBOX';
export const DISPLAYED_AT_LOCATION_POPUPBOX = 'DISPLAYED_AT_LOCATION_POPUPBOX';
