/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';
import { updateChat } from 'Store/Config/Config.action';
import { getLocaleFromUrl } from 'Util/Global';

import Meta from './Meta.component';

export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateChatIsOnline: (chatIsOnline) => dispatch(updateChat({ chatIsOnline }))
});

/** @namespace Sofacompany/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    image: state.MetaReducer.image,
    hreflangs: state.MetaReducer.hreflangs,
    is_ready: state.MetaReducer.is_ready,
    default_meta: state.MetaReducer.default_meta,
    custom_url_type: state.MetaReducer.custom_url_type,
    customHreflangs: state.MetaReducer.customHreflangs,
    mw_seo_markup: state.MetaReducer.mw_seo_markup,
    head_includes: state.ConfigReducer.head_includes,
    absolute_footer: state.ConfigReducer.absolute_footer,
    code: state.ConfigReducer.code,
    chatIsOnline: state.ConfigReducer.chatIsOnline
});

/** @namespace Sofacompany/Component/Meta/Container/MetaContainer */
export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        ...SourceMetaContainer.propTypes,
        image: PropTypes.string,
        customHreflangs: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),
        hreflangs: PropTypes.array,
        mw_seo_markup: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        head_includes: PropTypes.string,
        absolute_footer: PropTypes.string,
        default_description: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        description: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        title: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ])
    };

    static defaultProps = {
        ...SourceMetaContainer.defaultProps,
        image: '',
        customHreflangs: [],
        hreflangs: [],
        head_includes: null,
        absolute_footer: null
    };

    containerFunctions = {
        hrefLangCapitalize: this.hrefLangCapitalize.bind(this)
    };

    componentDidMount() {
        this.appendHeadScripts();
        this.appendBodyScripts();
    }

    componentDidUpdate(prevProps) {
        const { code } = this.props;
        const {
            code: prevcode
        } = prevProps;

        if (code !== prevcode) {
            this.appendBodyScripts();
            this.appendHeadScripts();
        }
    }

    appendHeadScripts() {
        const { head_includes } = this.props;

        if (head_includes) {
            document.querySelector('head').insertAdjacentHTML('afterbegin', head_includes);
        }
    }

    appendBodyScripts() {
        const { absolute_footer, code } = this.props;
        const local = getLocaleFromUrl()?.split('-')?.[1];
        const fullLocal = getLocaleFromUrl()?.replace('-', '_');
        if (absolute_footer && (code === fullLocal || code === local)) {
            document.querySelector('body').insertAdjacentHTML('beforeend', absolute_footer);
            setTimeout(() => {
                const salesForceScript = document.querySelector('#sales-force-script');
                if (salesForceScript) {
                    // eslint-disable-next-line no-eval
                    eval(salesForceScript.innerHTML);
                    this.checkForSalesForce();
                }
            // eslint-disable-next-line no-magic-numbers
            }, 1000);
        }
    }

    // eslint-disable-next-line no-magic-numbers
    checkForSalesForce(times = 5, type = 'all') {
        const { updateChatIsOnline } = this.props;
        if (times <= 0) {
            return 0;
        }
        if (type === 'all' || type === 'id') {
            setTimeout(() => {
                const chat = document.getElementsByClassName('helpButtonEnabled')[0];
                if (chat) {
                    updateChatIsOnline(true);
                } else {
                    const newCount = times - 1;

                    this.checkForSalesForce(newCount, 'id');
                }
            }, '2000');
        }

        if (type === 'all' || type === 'script') {
            setTimeout(() => {
                if (window.embedded_svc) {
                    window.embedded_svc?.addEventHandler('onAvailability', (data) => {
                        updateChatIsOnline(!!data?.isAgentAvailable);
                    });
                } else {
                    const newCount = times - 1;

                    this.checkForSalesForce(newCount, 'script');
                }
            }, '2000');
        }
    }

    hrefLangCapitalize(string) {
        const array = string.split('-');
        return `${array[0]}-${array[1].toUpperCase()}`;
    }

    _getImage() {
        const { image } = this.props;

        return image;
    }

    _getMwSeoMeta() {
        const { mw_seo_markup } = this.props;

        if (!mw_seo_markup?.social_markup) {
            return null;
        }

        const metaTagRegex = /<meta property="([^"]+)" content="([^"]+)"\/>/g;

        // Ensure we handle both array and string cases
        const markupString = Array.isArray(mw_seo_markup.social_markup)
            ? mw_seo_markup.social_markup.join(' ')
            : mw_seo_markup.social_markup;

        const matches = Array.from(markupString.matchAll(metaTagRegex));

        return matches.reduce((acc, match) => {
            acc[match[1]] = match[2]; // Use the property value as the key
            return acc;
        }, {});
    }

    _getMetadata() {
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords(),
            image: this._getImage(),
            robots: this._getRobots(),
            'prerender-status-code': this._getStatusCode()
        };

        const mwSeoMeta = this._getMwSeoMeta();

        const mergedMetaData = { ...meta, ...mwSeoMeta };

        return this._generateMetaFromMetadata(mergedMetaData);
    }

    render() {
        return (
            <Meta
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
